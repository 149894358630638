<template>
  <div>
    <InternetDetectionVue></InternetDetectionVue>
    <DownloadingVue></DownloadingVue>
    <div class="s-layout">
      <!-- Sidebar -->
      <StorekeeperDashboardSidebar></StorekeeperDashboardSidebar>
      <!-- Header -->
      <div
        class="s-layout__header row justify-content-between align-items-center position-fixed py-2"
      >
        
        <div class="col">
          <AlertVue class="border"></AlertVue>
        </div>

        <diV class="col-auto d-flex align-items-center">
          <span>
            <i class="bi bi-question-circle fs-3"></i>
          </span>
          <span>
            <i class="bi bi-gear fs-3"></i>
          </span>
          <span>
            <i class="bi bi-grid-3x3-gap-fill fs-3"></i>
          </span>
          <button
            @click="
              logout_display
                ? (logout_display = false)
                : (logout_display = true)
            "
            class="btn d-flex align-items-center px-1 py-2 text-warning rounded"
          >
            <span>
              <i class="bi bi-person-circle fs-3"></i>
            </span>
            <span class="">
              {{ authData.name }}
            </span>
          </button>
        </diV>
        <br />
        <div
          v-if="logout_display"
          :style="{
            width: '200px',
            height: '100px',
            position: 'absolute',
            top: '4.3em',
            right: '10px',
          }"
          class="position-absolute p-3 bg-white rounded shadow align-items-end"
        >
          <button @click="logout()" class="btn btn-danger col-auto px-3 py-2">
            Deconnecter
          </button>
        </div>
      </div>
      <!-- Content -->
      <main class="s-layout__content">
        <div class="s-layout__page-title">
          <i class="bi bi-chevron-right"></i> {{ $route.meta.title }}
          <br />
        </div>
        <AlertVue class="border position-absolute right-0 top-0"></AlertVue>
        <hr />
        <router-view />
      </main>
    </div>
    
    <br />
  </div>
</template>
<script>
import AlertVue from "../views/notification/Alert.vue";
import StorekeeperDashboardSidebar from "../components/sidebars/StorekeeperDashboardSidebar.vue";
import InternetDetectionVue from "../views/notification/InternetDetection.vue";
import DownloadingVue from "../views/notification/Downloading.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    StorekeeperDashboardSidebar,
    AlertVue,
    InternetDetectionVue,
    DownloadingVue
  },
  data() {
    return {
      display_sidebar: false,
      logout_display: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      // this.$router.push({ name: "login" });
    },
  },
};
</script>

<template>
  <div>
    <div class="s-layout">
      <!-- Sidebar -->

      <OujdaFoodDashboardSidebarVue></OujdaFoodDashboardSidebarVue>
      <!-- <router-link :to="{name:'oujda-food-orders-index'}">
        Commandes
      </router-link> -->
      <!-- Header -->
      <div
        class="s-layout__header row justify-content-between align-items-center position-fixed py-2"
      >
        <div class="col">
          <AlertVue class="border"></AlertVue>
        </div>

        <diV class="col-auto d-flex align-items-center">
          <span>
            <i class="bi bi-question-circle fs-3"></i>
          </span>
          <span>
            <i class="bi bi-gear fs-3"></i>
          </span>
          <span>
            <i class="bi bi-grid-3x3-gap-fill fs-3"></i>
          </span>
          <div class="d-flex align-items-center px-1 py-2 border border-warning text-danger rounded">
            <span>
              <i class="bi bi-person-circle fs-3"></i>
            </span>
            <span class="">
              {{ authData.name }}
            </span>
          </div>
        </diV>
      </div>
      <!-- Content -->
      <main class="s-layout__content">
        <div class="s-layout__page-title">
          <i class="bi bi-chevron-right"></i> {{ $route.meta.title }}
          <br />
        </div>
        <router-view />
      </main>
    </div>
    <!-- <DashboardSidebarVue></DashboardSidebarVue> -->
    <br />
  </div>
</template>
<script>
import AlertVue from "../views/notification/Alert.vue";
import OujdaFoodDashboardSidebarVue from "../components/sidebars/OujdaFoodDashboardSidebar.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    OujdaFoodDashboardSidebarVue,
    AlertVue,
  },
  data() {
    return {
      display_sidebar: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
  },
  mounted() {},
};
</script>

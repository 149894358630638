import constant from "../../constant";
import axios from "axios";
const api = constant.api + "credits";
// initial state
const state = () => ({
  all: [],
  credits: [],
  suppliersCredit: [],
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getCredits: (state) => {
    return state.credits;
  },

  getCreditsSuppliers: (state) => {
    return state.suppliersCredit;
  },
  getTotalUnpaidSum: (state) =>
    state.credits.reduce((acc, val) => acc + parseFloat(val.totalUnpaid), 0),
  getTotalTTCSum: (state) =>
    state.credits.reduce((acc, val) => acc + parseFloat(val.totalTTC), 0),
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let credits = response.data;
      commit("setCredits", credits);
    });
  },
  async fetchCreditsSuppliers({ commit }) {
    await axios.get(api + "_suppliers").then((response) => {
      let credits = response.data;
      commit("SET_CREDITS_SUPPLIRES", credits);
    });
  },

  async getCreditBalance({ commit }) {
    await axios.get(api + "_balance").then((response) => {
      let credits = response.data;
      commit("setCredits", credits);
    });
  },

  async getCustomerCreditBalance({ commit }, customer_reference) {
    await axios
      .get(api + "_balance_customer/" + customer_reference)
      .then((response) => {
        let credits = response.data;
        console.log(credits);
        commit("setCustomerCredits", credits);
      });
  },
  async paySale({ commit, dispatch }, reference) {
    await axios.get(api + "_balance_pay_sale/" + reference).then((response) => {
      let credits = response.data.data;
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setCustomerCredits", credits);
    });
  },

  async refund({ commit }, reference) {
    await axios.get(api + "_balance_refund/" + reference).then((response) => {
      let credits = response.data;
      commit("setCustomerCredits", credits);
    });
  },

  async search({ state }, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.credits = state.all.filter(function (user) {
      return (
        user.customer.firstName.toUpperCase().indexOf(value.toUpperCase()) >
          -1 ||
        user.customer.lastName.toUpperCase().indexOf(value.toUpperCase()) >
          -1 ||
        user.customer.fullName.toUpperCase().indexOf(value.toUpperCase()) >
          -1 ||
        user.customer_reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },

  async printAll({ dispatch }) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "La liste des crédits clients est en cours de téléchargement. Veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .get(api + "_print_all", {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Credits clients.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  setCredits(state, credits) {
    state.all = credits;
    state.credits = credits;
  },

  SET_CREDITS_SUPPLIRES(state, credits) {
    state.all = credits;
    state.suppliersCredit = credits;
  },

  SET_All(state, data) {
    state.all = data;
    state.credits = data;
  },

  setCustomerCredits(state, data) {
    state.all = data;
    state.credits = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { render, staticRenderFns } from "./StorekeeperDashboardSidebar.vue?vue&type=template&id=83832eec&scoped=true&"
import script from "./StorekeeperDashboardSidebar.vue?vue&type=script&lang=js&"
export * from "./StorekeeperDashboardSidebar.vue?vue&type=script&lang=js&"
import style0 from "./StorekeeperDashboardSidebar.vue?vue&type=style&index=0&id=83832eec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83832eec",
  null
  
)

export default component.exports
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "customers";
// initial state
const state = () => ({
  all: [],
  customers: [],
  customer_types: [],
  customer: {},
  customer_type: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getCustomer: (state) => {
    return state.customer;
  },

  getCustomers: (state) => {
    return state.customers;
  },

  getCustomerType: (state) => {
    return state.customer_type;
  },

  getCustomerTypes: (state) => {
    return state.customer_types;
  },
};

// actions
const actions = {
  async getCustomerTypes({ commit }) {
    await axios.get(api + "_types").then((response) => {
      let customer_types = response.data;
      commit("setCustomerTypes", customer_types);
    });
  },
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let customers = response.data;
      commit("setCustomers", customers);
    });
  },

  async getSellers({ commit }) {
    await axios.get(api + "_sellers").then((response) => {
      let customers = response.data;
      commit("setCustomers", customers);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let customer = response.data;
      commit("setCustomer", customer);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "customers-index",
        });
      }
    });
  },

  async storeType({ dispatch }, data) {
    await axios.post(api + "_types", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "customers-types-index",
        });
      }
    });
  },

  async update({ commit, dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "customers-index",
        });
      }
    });
  },

  async updateType({ dispatch }, data) {
    await axios.put(api + "/_types" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "customers-types-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let customers = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setCustomers", customers);
    });
  },

  async destroyType({ commit, dispatch }, reference) {
    await axios.delete(api + "/_types" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let customer_types = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setCustomerTypes", customer_types);
    });
  },
};

// mutations
const mutations = {
  setCustomers(state, customers) {
    state.all = customers;
    state.customers = customers;
  },
  setCustomer(state, customer) {
    state.customer = customer;
  },

  setCustomerTypes(state, customer_types) {
    // state.all = customer_types;
    state.customer_types = customer_types;
  },
  setCustomerType(state, customer_type) {
    state.customer_type = customer_type;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.customers = state.all.filter(function (customer) {
      return (
        customer.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        customer.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        customer.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        customer.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('InternetDetectionVue'),_c('DownloadingVue'),_c('div',{staticClass:"s-layout"},[_c('CashierDashboardSidebarVue'),_c('div',{staticClass:"s-layout__header row justify-content-between align-items-center position-fixed py-2"},[_c('div',{staticClass:"col"},[_c('AlertVue',{staticClass:"border"})],1),_c('diV',{staticClass:"col-auto d-flex align-items-center"},[_c('span',[_c('i',{staticClass:"bi bi-question-circle fs-3"})]),_c('span',[_c('i',{staticClass:"bi bi-gear fs-3"})]),_c('span',[_c('i',{staticClass:"bi bi-grid-3x3-gap-fill fs-3"})]),_c('button',{staticClass:"btn d-flex align-items-center px-1 py-2 text-warning rounded",on:{"click":function($event){_vm.logout_display
              ? (_vm.logout_display = false)
              : (_vm.logout_display = true)}}},[_c('span',[_c('i',{staticClass:"bi bi-person-circle fs-3"})]),_c('span',{},[_vm._v(" "+_vm._s(_vm.authData.name)+" ")])])]),_c('br'),(_vm.logout_display)?_c('div',{staticClass:"position-absolute p-3 bg-white rounded shadow align-items-end",style:({
          width: '200px',
          height: '100px',
          position: 'absolute',
          top: '4.3em',
          right: '10px',
        })},[_c('button',{staticClass:"btn btn-danger col-auto px-3 py-2",on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Deconnecter ")])]):_vm._e()],1),_c('main',{staticClass:"s-layout__content"},[_c('div',{staticClass:"s-layout__page-title"},[_c('i',{staticClass:"bi bi-chevron-right"}),_vm._v(" "+_vm._s(_vm.$route.meta.title)+" "),_c('br')]),_c('AlertVue',{staticClass:"border position-absolute right-0 top-0"}),_c('hr'),_c('router-view')],1)],1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "invoices";
// initial state
const state = () => ({
  all: [],
  invoices: [],
  invoice: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getInvoice: (state) => {
    return state.invoice;
  },

  getInvoices: (state) => {
    return state.invoices;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let invoices = response.data;
      commit("setInvoices", invoices);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let invoice = response.data;
      commit("setInvoice", invoice);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "invoices-index" });
    });
  },
 
  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "invoices-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let invoices = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setInvoices", invoices);
    });
  },
};

// mutations
const mutations = {
  setInvoices(state, invoices) {
    state.all = invoices;
    state.invoices = invoices;
  },
  setInvoice(state, invoice) {
    state.invoice = invoice;
  },
  async search(state, value) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
    console.log(value);
    state.invoices = state.all.filter(function (invoice) {
      return (
        invoice.firstName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        invoice.lastName.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        invoice.phone.toUpperCase().indexOf(value.toUpperCase()) > -1 ||
        invoice.reference.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <button
      class="menu-button"
      @click="toggleSidebar"
      :class="{ open: isSidebarOpen }"
    >
      Menu btn
    </button>
    <div class="s-layout__sidebar" :class="{ hide: !isSidebarOpen }">
      <div class="s-sidebar__trigger">
        <i class="bi bi-logo bi-kanban-fill"></i>
        <div>PSM</div>
      </div>
      <br />
      <br />
      <nav class="s-sidebar__nav">
        <ul class="my-5">
          <!-- dashboard -->
          <li>
            <router-link
              :to="{ name: 'storekeeper-dashboard' }"
              :class="
                $route.name.includes('storekeeper-dashboard') ? 'active' : ''
              "
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-back"></i>
              <div>Dashboard</div>
            </router-link>
          </li>

          <!-- storekeeper-sales -->
          <li>
            <router-link
              :to="{ name: 'storekeeper-sales-index' }"
              :class="$route.name.includes('storekeeper-sales') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-bag-check-fill"></i>
              <div>Ventes</div>
            </router-link>
          </li>

          <!-- storekeeper-stocks -->
          <li>
            <router-link
              :to="{ name: 'storekeeper-stocks-index' }"
              :class="
                $route.name.includes('storekeeper-stocks') ? 'active' : ''
              "
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-database-fill"></i>
              <div>Stocks</div>
            </router-link>
          </li>

          <!-- storekeeper-orders -->
          <li>
            <router-link
              :to="{ name: 'storekeeper-orders-index' }"
              :class="
                $route.name.includes('storekeeper-orders') ? 'active' : ''
              "
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-archive-fill"></i>
              <div>Commandes</div>
            </router-link>
          </li>

          <div class="my-5"></div>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settings_display: false,
      security_display: false,
      isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>
<style scoped>
.bi {
  font-size: 18px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
</style>

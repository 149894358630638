<template>
  <div class="s-layout__sidebar">
    <div class="s-sidebar__trigger">
      <i class="bi bi-logo bi-kanban-fill"></i>
      <div>PSM - OUJDAFOOD</div>
    </div>
    <br /><br />
    <nav class="s-sidebar__nav">
      <ul class="mt-5">
        <!-- dashboard -->
        <li>
          <router-link :to="{ name: 'dashboard' }" class="s-sidebar__nav-link">
            <i class="bi bi-back"></i>
            <div>Dashboard</div>
          </router-link>
        </li>

        <!-- sales -->
        <li>
          <router-link
            :to="{ name: 'sales-index' }"
            class="s-sidebar__nav-link"
          >
            <i class="bi bi-bag-check-fill"></i>
            <div>Ventes</div>
          </router-link>
        </li>

        <!-- stocks -->
        <li>
          <router-link
            :to="{ name: 'stocks-index' }"
            class="s-sidebar__nav-link"
          >
            <i class="bi bi-database-fill"></i>
            <div>Stocks</div>
          </router-link>
        </li>

        <!-- payments -->
        <li>
          <router-link
            :to="{ name: 'payments-index' }"
            class="s-sidebar__nav-link"
          >
            <i class="bi bi-currency-bitcoin"></i>
            <div>Paiements</div>
          </router-link>
        </li>

        <!-- orders -->
        <li>
          <router-link
            :to="{ name: 'oujda-food-orders-index' }"
            class="s-sidebar__nav-link"
          >
            <i class="bi bi-archive-fill"></i>
            <div>Commandes</div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settings_display: false,
    };
  },
};
</script>
<style scoped>
.bi {
  font-size: 18px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
</style>
